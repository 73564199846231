import "@ungap/custom-elements";
import Swiper, { Autoplay, Pagination, Controller } from "swiper";

Swiper.use([Autoplay, Pagination, Controller]);

class SliderImages extends window.HTMLDivElement {
	constructor(...args) {
		const self = super(...args);
		self.init();
		return self;
	}

	init() {
		this.props = this.getInitialProps();
		this.resolveElements();
	}

	getInitialProps() {
		let data = {};
		try {
			data = JSON.parse(
				this.querySelector('script[type="application/json"]').innerText
			);
		} catch (e) {}

		return data;
	}

	resolveElements() {
		this._slider = this.querySelector("[data-slider]");
		this._buttonNext = this.querySelector('[data-slider-button="next"]');
		this._buttonPrev = this.querySelector('[data-slider-button="prev"]');
	}

	connectedCallback() {
		this.initSlider();
	}

	initSlider() {
		this._slider.classList.add("swiper-container");
		const { options } = this.props;
		const config = {
			navigation: {
				nextEl: this._buttonNext,
				prevEl: this._buttonPrev,
			},
			...options,
		};

		if (options.autoplay && options.autoplaySpeed) {
			const initialAutoplay =
				typeof options.autoplay === "boolean" ? {} : initialAutoplay;

			config.autoplay = {
				...initialAutoplay,
				delay: options.autoplaySpeed,
			};
		}

		this.slider = new Swiper(this._slider, config);

		this._slider.addEventListener("mouseenter", () => {
			this.slider.autoplay.stop();
		});

		this._slider.addEventListener("mouseleave", () => {
			this.slider.autoplay.start();
		});
	}
}

window.customElements.define("lagans-slider", SliderImages, { extends: "div" });

window.addEventListener("load", function () {
	const defaultOptions = {
		speed: 2000,
		loop: true,
	};

	const sliders = [
		{
			sliderClass: ".js-product-slider",
			slideClass: ".js-product-slide",
			pagNext: ".swiper-button-next",
			pagPrev: ".swiper-button-prev",
			thumbnails: ".js-product-thumbnail",
			options: {
				...defaultOptions,
				noSwiping: true,
				autoHeight: true,
			},
		},
	];

	const addSliderWrapper = (sliderEl) => {
		const slideContent = sliderEl.innerHTML;

		sliderEl.innerHTML = "";

		const wrapper = document.createElement("div");
		wrapper.classList.add("swiper-wrapper");

		wrapper.innerHTML = slideContent;

		sliderEl.appendChild(wrapper);
	};

	sliders.forEach(
		({
			sliderClass,
			slideClass,
			pagNext,
			pagPrev,
			options,
			thumbnails,
		}) => {
			const sliderEl = document.querySelector(sliderClass);

			if (!sliderEl) {
				return;
			}

			const slides = sliderEl.querySelectorAll(slideClass);

			const isVariable = sliderEl.classList.contains("js-variable-slide");

			if (slides.length <= 1) return;

			// add required classes
			sliderEl.classList.add("swiper-container");
			slides.forEach((slide) => slide.classList.add("swiper-slide"));
			addSliderWrapper(sliderEl);

			const slider = new Swiper(sliderClass, options);

			if (pagPrev) {
				document
					.querySelector(pagPrev)
					.addEventListener("click", () => {
						slider.slidePrev();
					});
			}

			if (pagNext) {
				document
					.querySelector(pagNext)
					.addEventListener("click", () => slider.slideNext());
			}

			if (thumbnails) {
				const thumbs = document.querySelectorAll(thumbnails);

				slider.on("slideChange", (s) => {
					thumbs.forEach((t) =>
						t.dataset.slideIdx == s.activeIndex
							? t.classList.add("active")
							: t.classList.remove("active")
					);
				});

				thumbs.forEach((thumb) => {
					thumb.addEventListener("click", (e) => {
						slider.slideTo(thumb.dataset.slideIdx);
					});
				});
			}

			if (isVariable) {
				const variableWrapper = document.querySelector(
					".variable-items-wrapper"
				);

				const variableItems =
					variableWrapper.querySelectorAll(".variable-item");

				[...variableItems].forEach((item) =>
					item.addEventListener("click", () => {
						const selectedColor = item.dataset.value;
						let selected = 1;

						slider.slides.each(
							(item, idx) =>
								item.dataset.color === selectedColor &&
								(selected = idx)
						);

						slider.slideTo(selected);
					})
				);
			}
		}
	);
});
