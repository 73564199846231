const mobileMenuWrap = document.querySelector(".js-mobile-menu-wrap");
const links = document.querySelectorAll(".js-mobile-menu__link");
const toggleMenuBtn = document.querySelectorAll(".js-toggle-menu");

const linkClickHandler = (e) => {
	const subMenu = e.currentTarget.parentElement.querySelector(
		".js-mobile-menu__sub-menu"
	);

	let path = e.path || (e.composedPath && e.composedPath());

	const iconClick = path.find((p) =>
		p.classList?.contains("dropdown-target")
	);

	const isDummy = e.currentTarget.href.slice(-1) == "#";

	if (subMenu && (iconClick || isDummy)) {
		e.preventDefault();
		subMenu.classList.toggle("visible");
	}
};

const menuToggleHandler = () => mobileMenuWrap.classList.toggle("visible");

links.forEach((item) => item.addEventListener("click", linkClickHandler));

toggleMenuBtn.forEach((btn) =>
	btn.addEventListener("click", menuToggleHandler)
);
